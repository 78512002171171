<template>
	<div class="inviteUsersBox">
		<el-form :inline="true" :model="param" ref="param" class="demo-form-inline">
			
			<el-form-item label="收入类型" prop="searchType">
				<el-select size="mini" v-model="param.searchType" placeholder="请选择">
					<el-option label="充值" value="1"></el-option>
					<el-option label="其他" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button size="mini" type="primary" :loading="loadingBtn" @click="onSubmit">查询</el-button>
				<el-button size="mini" @click="resetForm('param')">重置</el-button>
			</el-form-item>
		</el-form>

		<el-table  :data="list" v-loading="loadingShow" border style="width: 100%">
	
		
			<el-table-column prop="t_idcard" label="用户ID"></el-table-column>
			<el-table-column prop="t_nickName" label="贡献用户"></el-table-column>
			<el-table-column prop="t_wallet_money" label="收入金额"></el-table-column>
			<el-table-column prop="t_recharge_money" label="充值金额"></el-table-column>
			<el-table-column prop="t_wallet_money_time" label="充值时间"></el-table-column>
			<!-- <el-table-column prop="t_wallet_type" label="是否用户充值">
			</el-table-column>
			<el-table-column prop="city" label="分润">
			</el-table-column> -->
			<el-table-column prop="address" label="充值类型">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.t_recharge_type == 0">VIP</div>
						<div v-if="scope.row.t_recharge_type == 1">金币</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="address" label="推荐关系">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.t_wallet_type == 1">直推用户</div>
						<div v-if="scope.row.t_wallet_type == 2">主播推广用户充值</div>
						<div v-if="scope.row.t_wallet_type == 3">申请提现</div>
						<div v-if="scope.row.t_wallet_type == 4">提现失败,原路返回</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="t_payment_type" label="结算模式">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.t_payment_type == 0">支付宝</div>
						<div v-if="scope.row.t_payment_type == 1">微信</div>
					</div>
				</template>
			</el-table-column>
		
		</el-table>

		<el-pagination background layout="total, prev, pager, next, jumper" :page-size="param.pageSize" :current-page.sync="param.page"
		:total="total" @current-change="currentChange"></el-pagination>
		
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialog:{
					promoteUsersShow:false,
					rechargeDetailsShow:false,
				},
				
				param: {
					searchType:null,  //1:充值 2:其他
					page: 1, //   1
					pageSize: 10, //   10
				},
				loadingShow: false,
				loadingBtn:false,
				list: [],
				total: 0,
				
				
				
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
		
			async onSubmit() {
				console.log('submit!');
				this.loadingBtn = true
				await this.getList()
				this.loadingBtn = false
			},
			async getList() {
				console.log("save")
				let that = this
				let param = {
					url: "myMoneyFromToList",
					data: this.param
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					that.list = []
					if (res.status == 0) {
						that.list = res.data.list
						that.total = res.data.total
					}
				})
				this.loadingShow = false
			},
			currentChange(page) {
				this.param.page = page
				this.getList()
			},
			resetForm(formName) {
				console.log("resetForm")
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style scoped>
	
</style>
